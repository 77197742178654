#footer {
	padding: 1.4em;
	background-color: #232323;
	color: #fafafa;
	grid-area: footer;
}

app-stores {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.footer-links {
	font-size: 1.3rem;
}

.footer-links .cols {
	padding: 0 1.5rem;
}

.footer-links a svg {
	display: inline-block;
	transition: transform 600ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.footer-links a:hover svg {
	transform: rotate(1turn) scale(1.3);
}

.app-stores {
	gap: 18px;
}
