#nav {
	font-size: 1.2em;
	gap: 5px;
	background-color: rgba(0, 0, 0, 0.8);
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
}

#nav > a.nav-link {
	line-height: calc(var(--nav-height, 3rem) - 1.3em);
	vertical-align: middle;
	text-align: center;
	border-radius: 0;
	padding: var(--button-padding, 0.3em);
	transition: background-color 300ms ease-in-out;
}

#nav > button {
	background-color: transparent;
	color: inherit;
	border: none;
	min-width: 47px;
}

#nav > button, #nav > .nav-link {
	max-width: 230px;
}

#nav > a.nav-link:hover, #nav > button:hover, #nav > button:focus,
#nav > a.nav-link:focus {
	background-color: var(--button-primary-hover-background);
}

#nav > a.nav-link.current-link, #nav > a.nav-link:active {
	background-color: var(--button-primary-background);
	border-color: #043469;
	box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.3) inset;
}

#install-btn {
	margin-left: auto;
	margin-right: 0.4em;
}

#install-btn svg.icon {
	margin-bottom: 10px;
}

@media (max-width: 800px) {
	#nav > a.nav-link {
		line-height: var(--nav-height, 3rem);
	}
}
