.event-list.grid {
	grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
	grid-template-rows: masonry;
	gap: 12px;
	justify-content: center;
}

.event-item {
	padding: 0.8em 1em;
	margin-top: 0.6em;
	border: 1px solid #dadada;
	border-radius: 6px;
	grid-template-areas: "image title link" "image dates dates" "image location location" "offers offers offers";
	grid-template-rows: auto;
	grid-template-columns: 6em 1fr 1rem;
	grid-gap: 0.8em;
}

.event-item .event-title {
	text-decoration: none;
}

.event-item .event-offers {
	grid-area: offers;
	justify-content: center;
	gap: 8px;
}

.event-item .event-offers a {
	text-decoration: none;
}

@media (max-width: 800px) {
	.event-item {
		grid-template-areas: "title link" "image image" "dates dates" "location location" "offers offers";
		grid-template-rows: auto 5em auto auto auto;
		grid-template-columns: 1fr auto;
	}

	.event-item > .event-image-container > .event-image {
		width: 100%;
		height: auto;
		max-height: 5em;
	}
}

.event-item > .event-location {
	grid-area: location;
}

.event-item > .event-title {
	grid-area: title;
}

.event-item > .event-image-container {
	grid-area: image;
}

.event-item > .event-image-container > .event-image {
	width: auto;
	height: 100%;
	border-radius: 5px;
}

.event-item > .event-dates {
	grid-area: dates;
}

.event-item > .event-description {
	grid-area: description;
}

.event-item > .event-link {
	grid-area: link;
}
