article > footer {
	margin-top: 1.85rem;
}

#posts.grid {
	grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
	grid-template-rows: masonry;
	gap: 1.2rem;
	justify-content: space-evenly;
}

#posts .post-title {
	margin-bottom: 2px;
}

.post-preview {
	transform: none;
	transition: transform 400ms ease-in-out;
	text-decoration: none;
}

.post-preview:hover, .post-preview:focus {
	transform: scale(1.1) rotate(-3deg);
}

.pagination-nav {
	padding: 1.2rem;
}

.btn.pagination {
	text-decoration: none;
	color: var(--alt-color, #fefefe);
}

#main .btn.pagination:visited, .btn.pagination:hover {
	color: var(--alt-color, #fefefe);
}

.author-by-line {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 2px;
}

.status-box .icon.flip {
	transform: rotateY(.5turn);
}

article {
	--map-height: 300px;
}
