#candidates {
	gap: 8px;
}

#candidates .mayor-candidate {
	flex-basis: 420px;
	flex-grow: 0;
	flex-shrink: 1;
	color: inherit;
	text-decoration: none;
}

#mayor-profile .flex.mayor-contact-links {
	gap: 1.3rem;
}

.mayor-candidate .mayor-race-event-description {
	max-width: 400px;
}

.mayor-candidate .mayor-race-event:not(:last-of-type) {
	margin-bottom: 8px;
	padding-bottom: 18px;
	border-bottom: 2px dashed #dadada;
}

.donate-link {
	text-decoration: none;
	vertical-align: bottom;
}

.donate-link svg {
	vertical-align: bottom;
}

.main-fund-links {
	gap: 1.2rem;
}

a.btn.btn-primary.mayor-cta, a.btn.btn-primary.mayor-cta:visited, a.btn.btn-primary.mayor-cta:hover {
	max-width: 25ch;
	text-decoration: none;
	font-weight: bold;
	color: var(--button-primary-color);
}

.grid.mayor-events {
	grid-template-columns: repeat(auto-fit, minmax(350px, 420px));
	gap: 1.2rem;
}

.mayor-event-image {
	aspect-ratio: 4 / 3;
	border-radius: 12px;
	object-fit: cover;
	object-position: center;
	display: block;
	width: 640px;
	max-width: 95%;
	height: auto;
	margin: 2em auto;
}

.btn.event-share-btn {
	margin-top: 0.8em;
	font-size: 1.1rem;
	padding: 0.6em 1.2em;
}
