@import url("https://cdn.kernvalley.us/css/core-css/theme/properties.css");
@import url("https://cdn.kernvalley.us/css/core-css/theme/default/index.css");

:root[data-theme], :root {
	--accent-color: #b70c0c;
	--scrollbar-color: var(--accent-color);
	--button-primary-background: var(--accent-color);
	--button-primary-hover-background: #a20a0a;
	--button-primary-active-background: #820606;
	--header-height: 100vh;
	--viewport-height: var(--header-height);
}

:root[data-theme][data-layout="right-sidebar"] {
	--header-height: 0;
}

@supports (height: 1dvh) {
	:root[data-theme]:not([data-layout="right-sidebar"]), :root {
		--header-height: 100dvh;
	}
}

:root.js {
	--map-width: 100%;
	--map-height: calc(90vh - var(--nav-height, 4rem));
	--sidebar-max-width: 30vw;
	--scrollbar-color: var(--accent-color);
	--scrollbar-track: transparent;
	--title-font: "Rye";
	--ad-block-width: 30vw;

	--ad-block-text-height: 170px;
}

#sidebar {
	--ad-block-width: 30vw;
	--ad-block-stack-width: var(--ad-block-width);
	--ad-block-text-width: var(--ad-block-width);
	--ad-block-image-width: var(--ad-block-width);
}

@media (max-width: 800px) {
	#sidebar {
		--ad-block-width: 95vw;
	}
}
