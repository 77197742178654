@import url("./news.css");

#main {
	--link-color: #049ccf;
	--link-decoration: underline;
	margin: 6px 0;
}

#vendor-applications {
	gap: 12px;
}

.vendor-apps {
	gap: 8px;
}

.share-btns .flex.wrap {
	gap: 8px;
}

@media (min-width: 801px) {
	#main ad-block:not(.floating) {
		margin: 0.4em;
	}

	#main ad-block.floating.float-right {
		margin-left: 0.7em;
	}

	#main ad-block.floating.float-left {
		margin-right: 0.7em;
	}
}

@media (max-width: 800px) {
	#main {
		padding-left: 1em;
		padding-right: 1em;
	}

	#main ad-block.float-left, #main ad-block.float-right {
		margin: 0;
		float: none;
		display: block;
		--ad-block-width: 90vw;
		--ad-block-text-width: 90vw;
		--ad-block-stack-width: 90vw;
	}
}

leaflet-map::part(popup) {
	max-height: 60vh;
	max-width: 60vw;
	overflow: auto;
}

article p:first-letter {
	margin-left: 2em;
}

.highlighter-rouge:not(.language-plaintext) {
	color: #242424;
}

.user-form .btn-big.grow-1 {
	font-size: 1.1rem;
	max-width: 250px;
	padding: 0.8em;
}

.user-form a.btn {
	text-decoration: none;
}

.user-form a.btn:hover {
	color: var(--button-primary-color);
}

.spoiler {
	cursor: pointer;
	border-radius: 4px;
}

.spoiler:not(.revealed) {
	background-color: currentColor;
}

.section-heading {
	text-wrap: balance;
}

@media (prefers-color-scheme: light) {
	.section-heading {
		color: var(--accent-color);
	}
}
