@charset "UTF-8";
@import url("./vars.css");
@import url("https://cdn.kernvalley.us/css/normalize/normalize.css");
@import url("https://cdn.kernvalley.us/css/core-css/rem.css");
@import url("https://cdn.kernvalley.us/css/core-css/viewport.css");
@import url("https://cdn.kernvalley.us/css/core-css/element.css");
@import url("https://cdn.kernvalley.us/css/core-css/class-rules.css");
@import url("https://cdn.kernvalley.us/css/core-css/scrollbar.css");
@import url("https://cdn.kernvalley.us/css/core-css/cdn-fonts.css");
@import url("https://cdn.kernvalley.us/css/core-css/display-mode.css");
@import url("https://cdn.kernvalley.us/css/core-css/accordion.css");
@import url("https://cdn.kernvalley.us/css/core-css/forms.css");
@import url("./layout.css");
@import url("./events.css");
@import url("./contact.css");
@import url("./mayors.css");
@import url("./window.css");
@import url("./spinner.css");

img.avatar {
	vertical-align: middle;
	margin-left: 1.2em;
}

.pretty-input {
	border-color: currentColor;
	border-width: 0 0 1px 0;
	border-style: solid;
	background: inherit;
	color: inherit;
}

.nav-link .icon {
	transform: none;
	transition: transform 600ms ease-in-out;
}

.nav-link:hover .icon {
	transform: rotate(1turn) scale(1.3);
}

.flex.row > .btn.btn-wide {
	flex: 1 0 auto;
	margin: 0 0.6em;
	max-width: 200px;
}

input[type="time"] {
	max-width: 10ch;
}

iframe:not([frameborder]) {
	border: none;
}


:root {
	background-color: #aeaeae;
}

:root[data-theme="dark"] {
	background-color: #202020;
}

@media (prefers-color-scheme: dark) {
	:root:not([data-theme="light"]) {
		background-color: #202020;
	}
}

details:has(summary.btn svg.icon) {
	summary.btn svg.icon {
		transform: none;
		transition: transform 400ms linear;
	}

	&[open] summary.btn svg.icon {
		transform: rotate(0.5turn);
	}
}
