@import url("./header.css");
@import url("./nav.css");
@import url("./sidebar.css");
@import url("./main.css");
@import url("./footer.css");
@import url("./events.css");
@import url("https://cdn.kernvalley.us/css/core-css/layout/index.css");

weather-current {
	margin: 0;
}

@media screen {
	:root:not([data-layout]) > body.grid, :root[data-layout="default"] > body.grid {
		grid-template-areas: "header header header" "nav nav nav" ". main sidebar" "footer footer footer";
		grid-template-rows: calc(var(--viewport-height, 100vh) - var(--nav-height, 4rem)) var(--nav-height, 4rem) minmax(var(--main-height, 75vh), auto) minmax(var(--footer-height, 300px), auto);
		grid-template-columns: auto minmax(var(--main-min-width, 500px), var(--main-max-width, 1200px)) minmax(var(--sidebar-min-width, 300px), var(--sidebar-max-width, 400px));
		grid-column-gap: 1.2em;
	}
}

[data-columns="auto"] {
	column-count: auto;
	column-width: var(--column-width, 320px);
	column-gap: var(--column-gap, 12px);
	column-rule: 1px dashed currentColor;
}

@media (max-width: 800px) {
	:root:not([data-layout]) > body.grid, :root[data-layout="default"] > body.grid, body.grid {
		grid-template-areas: "header" "nav" "main" "sidebar" "footer";
		grid-template-rows: calc(var(--viewport-height, 100vh) - var(--nav-height, 4rem)) var(--nav-height, 4rem) minmax(75vh, auto) minmax(200px, auto) minmax(300px, auto);
		grid-template-columns: 100%;
		grid-column-gap: 0;
	}
}

.content {
	margin: 1.2em;
	padding: 0.4em 1.4em;
}

.embed-container {
	margin: 10px;
}

.browser-installs {
	justify-content: space-around;
}

article header {
	padding: 0.8rem;
}

.overflow-x-auto {
	overflow-x: auto;
}

fieldset {
	padding: 2.3em;
}

legend {
	font-size: 1.4em;
	font-weight: bold;
}

dialog {
	max-height: var(--dialog-max-height, 90vh);
	max-width: var(--dialog-max-width, 90vw);
	overflow: auto;
}

.no-margins {
	margin: 0;
}

.icon-3vw {
	height: 3vw;
	width: 3vw;
}

.icon-3vmax {
	height: 3vmax;
	width: 3vmax;
}

.form-group {
	border: 2px solid #b6b6b3;
	border-radius: 6px;
	padding: 2px;
}

.form-group > input {
	border: none;
	max-width: 80%;
}

button[is="share-button"] {
	bottom: 1rem;
	right: 1rem;
	border-radius: 50%;
	padding: 0.8rem;
	font-size: 1.5rem;
}

.floating-image {
	border-radius: 4px;
}

.floating-image.float-left {
	margin-right: 1em;
}

@media (max-width: 800px) {
	.mobile-clearfix {
		clear: both;
	}
}

#search-btn {
	bottom: 1rem;
	left: 1rem;
	border-radius: 50%;
	padding: 0.8rem;
	font-size: 1.5rem;
}

@media (max-width: 800px) {
	.mobile-hidden {
		display: none;
	}

	#main, #sidebar {
		margin: 6px;
	}
}

@media (min-width: 800px) {
	body.grid {
		grid-column-gap: 1.5rem;
	}
}

[data-flex-grow="0"] {
	flex-grow: 0;
}

[data-flex-grow="1"] {
	flex-grow: 1;
}

[data-flex-grow="2"] {
	flex-grow: 2;
}

[data-flex-grow="3"] {
	flex-grow: 3;
}

[data-flex-grow="4"] {
	flex-grow: 4;
}

[data-flex-shrink="0"] {
	flex-shrink: 0;
}

[data-flex-shrink="1"] {
	flex-shrink: 1;
}

[data-flex-shrink="2"] {
	flex-shrink: 2;
}

[data-flex-shrink="3"] {
	flex-shrink: 3;
}

[data-flex-shrink="4"] {
	flex-shrink: 4;
}
